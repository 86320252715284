<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#fff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index" :key="item.index">
            <template #title>
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs">
              <el-submenu
                v-if="subItem.subs"
                :index="subItem.index"
                :key="subItem.index"
              >
                <template #title>{{ subItem.title }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.subs"
                  :key="i"
                  :index="threeItem.index"
                  >{{ threeItem.title }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :index="subItem.index"
                :key="subItem.index"
                >{{ subItem.title }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <i :class="item.icon"></i>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
// import bus from "../common/bus";
export default {
  data() {
    return {
      items: [],
    };
  },
  created() {
    let types = localStorage.getItem('types')
    if(types==='专家'){
      this.items = [
        {
          icon: "el-icon-lx-home",
          index: "dashboard",
          title: "专家工作台",
        },
        {
          icon: "el-icon-lx-calendar",
          index: "registerList",
          title: "预约挂号",
        },
        {
          icon: "el-icon-user",
          index: "newList",
          title: "新开接诊",
        },
        {
          icon: "el-icon-s-order",
          index: "curativeList",
          title: "诊疗服务单",
        },
        {
          icon: "el-icon-s-custom",
          index: "memberList",
          title: "会员健康档案",
        }
      ]
    }else{
      this.items = [
        {
          icon: "el-icon-lx-home",
          index: "dashboard",
          title: "护士工作台",
        },
        {
          icon: "el-icon-s-order",
          index: "curativeList",
          title: "诊疗服务单",
        },
        {
          icon: "el-icon-s-custom",
          index: "memberList",
          title: "会员健康档案",
        },
        {
          icon: "el-icon-c-scale-to-original",
          index: "drugsList",
          title: "待确认药品",
        }
      ]
    }
    
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
    collapse() {
      return this.$store.state.collapse;
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
  padding-top: 40px;
}
.el-menu-item.is-active{
  background-color: #00a0e9!important;
}
.el-menu-item i{
  color: rgb(191 203 217)!important;
}
</style>
