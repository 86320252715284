import axios from 'axios';
import router from '../router'
import { ElNotification } from 'element-plus';
import globalData from './data';

const service = axios.create({
    baseURL: globalData.httpUrl,
    // baseURL: '/api',
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        // 根据缓存获取token值
        let token = localStorage.getItem('token') || ''
        if (token) config.headers['token'] = token
            // config.headers['Content-Type'] = 'Content-Type:application/json'
        return config
    },
    error => {
        console.log('请求被拦截', error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        // 返回值
        // 不用response.status === 200，因为不是200会进入err
        let res = response.data
            // 执行成功
        if (res.code === 1) return res.data;
        // token失效
        if (res.code === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("types");
            ElNotification.error({
                title: '错误',
                message: '请重新登录'
            });
            router.push({ path: '/login' });
        } else {
            // 其他错误
            ElNotification.error({
                title: '错误',
                message: res.msg
            });
        }
        // code !== 1时返回错误，阻止.then的执行
        return Promise.reject('执行错误', res.msg)
    },
    error => {
        // response.status !== 200时进入
        console.log('请求错误', error.message);
        let code = error.response.status
        if (code == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("types");
            ElNotification.error({
                title: '错误',
                message: '请重新登录'
            });
            router.push({ path: '/login' });
        } else {
            ElNotification.error({
                title: '错误',
                message: error.message
            })
        }

        return Promise.reject(error)
    }
);

export default service;

// localStorage.setItem('zheng', str);
// localStorage.getItem('zheng');
// localStorage.removeItem('zheng');