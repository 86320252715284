<template>
    <router-view />
</template>

<script>
export default {
    mounted() {
       if(JSON.stringify(this.$store.state.userInfo)=='{}') {
        this.$router.push("/login")   
       }
    }
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>
