import {
	createRouter,
	createWebHashHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
		path: '/',
		redirect: '/dashboard'
	},
	{
		path: "/",
		name: "Home",
		component: Home,
		children: [{
				path: "/dashboard",
				name: "dashboard",
				meta: {
					title: '专家工作台'
				},
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */
						"../views/Dashboard.vue")
			},
			{
				path: "/UserInfo",
				name: "userInfo",
				meta: {
					title: '个人中心'
				},
				component: () =>
					import("../views/UserInfo.vue")
			},
			{
				path: "/registerList",
				name: "registerList",
				meta: {
					title: '预约挂号'
				},
				component: () =>
					import("../views/register/list.vue")
			},
			{
				path: "/registerInfo",
				name: "registerInfo",
				meta: {
					title: '预约挂号详情'
				},
				component: () =>
					import("../views/register/info.vue")
			},
			{
				path: "/registerCase",
				name: "registerCase",
				meta: {
					title: '接诊病例'
				},
				component: () =>
					import("../views/register/case.vue")
			},
			{
				path: "/curativeList",
				name: "curativeList",
				meta: {
					title: '服务单'
				},
				component: () =>
					import("../views/curative/list.vue")
			},
			{
				path: "/curativeInfo",
				name: "curativeInfo",
				meta: {
					title: '服务单详情'
				},
				component: () =>
					import("../views/curative/info.vue")
			},
			{
				path: "/memberList",
				name: "memberList",
				meta: {
					title: '会员健康档案'
				},
				component: () =>
					import("../views/member/list.vue")
			},
			{
				path: "/memberInfo",
				name: "memberInfo",
				meta: {
					title: '会员详情'
				},
				component: () =>
					import("../views/member/info.vue")
			},
			{
				path: "/memberAdd",
				name: "memberAdd",
				meta: {
					// title: '新增报告'
					title: '新增日志'
				},
				component: () =>
					import("../views/member/add.vue")
			},
			{
				path: "/newList",
				name: "newList",
				meta: {
					title: '新开接诊'
				},
				component: () =>
					import("../views/new/list.vue")
			},
			{
				path: "/newEdit",
				name: "newEdit",
				//redirect: '/newEdit', //重定向地址，在面包屑中点击会重定向去的地址
				meta: {
					title: '编辑诊疗单'
				},
				component: () =>
					import("../views/new/edit.vue")
			},
			{
				path: "/newScoring",
				name: "newScoring",
				meta: {
					title: '评分表'
				},
				component: () =>
					import("../views/new/scoring.vue")
			},
			{
				path: "/drugsList",
				name: "drugsList",
				meta: {
					title: '待确认药品单'
				},
				component: () =>
					import("../views/drugs/list.vue")
			},
			{
				path: "/drugsInfo",
				name: "drugsInfo",
				meta: {
					title: '药品单详情'
				},
				component: () =>
					import("../views/drugs/info.vue")
			},

			{
				path: "/tabs",
				name: "tabs",
				meta: {
					title: '消息'
				},
				component: () =>
					import(
						/* webpackChunkName: "tabs" */
						"../views/Tabs.vue")
			},
			{
				path: "/permission",
				name: "permission",
				meta: {
					title: '权限管理',
					permission: true
				},
				component: () =>
					import(
						/* webpackChunkName: "permission" */
						"../views/Permission.vue")
			},
			{
				path: '/404',
				name: '404',
				meta: {
					title: '找不到页面'
				},
				component: () =>
					import( /* webpackChunkName: "404" */
						'../views/404.vue')
			},
			{
				path: '/403',
				name: '403',
				meta: {
					title: '没有权限'
				},
				component: () =>
					import( /* webpackChunkName: "403" */
						'../views/403.vue')
			},
		]
	},

	{
		path: "/login",
		name: "Login",
		meta: {
			title: '登录'
		},
		component: () =>
			import(
				/* webpackChunkName: "login" */
				"../views/Login.vue")
	}
];

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title} | 路印医护端`;
	const token = localStorage.getItem('token');
	if (!token && to.path !== '/login') {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
		// role === 'admin' ? next() : next('/403');
	} else {
		next();
	}
});

export default router;
