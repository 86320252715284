import request from '../utils/request';
export const fetchData = query => {
    return request({
        url: './table.json',
        method: 'get',
        params: query
    });
};

// 咨询项目
export const getProject = query => {
    return request({
        url: 'home/project',
        method: 'get',
        params: query
    });
};

// 登录
export const login = data => {
    return request({
        url: 'users/info_login',
        method: 'post',
        data: data
    });
};
export const outLogin = data => {
    return request({
        url: 'users/out_info',
        method: 'post',
        data: data
    });
};

// 个人中心
export const userInfo = data => {
    return request({
        url: 'info/user_info',
        method: 'post',
        data: data
    });
};
export const setPassword = data => {
    return request({
        url: 'info/change_password',
        method: 'post',
        data: data
    });
};

// 专家工作台排班
export const workingList = data => {
    return request({
        url: 'info/working_list',
        method: 'post',
        data: data
    });
};

// 预约挂号列表
export const registrationList = data => {
    return request({
        url: 'info/get_registration',
        method: 'post',
        data: data
    });
};
export const registrationInfo = data => {
    return request({
        url: 'info/registration_details',
        method: 'post',
        data: data
    });
};

// 诊疗服务单
export const diagnosisList = data => {
    return request({
        url: 'info/diagnosis_list',
        method: 'post',
        data: data
    });
};
export const diagnosisDetails = data => {
    return request({
        url: 'info/diagnosis_details',
        method: 'post',
        data: data
    });
};
// 疗效评估
export const infoAssess = data => {
    return request({
        url: 'info/assess',
        method: 'post',
        data: data
    });
};

// 会员健康档案
export const vipRecord = data => {
    return request({
        url: 'info/vip_record',
        method: 'post',
        data: data
    });
};
// 会员档案-基础信息
export const basicInformation = data => {
    return request({
        url: 'info/basic_information',
        method: 'post',
        data: data
    });
};
// 会员档案-健康信息
export const healthInformation = data => {
    return request({
        url: 'info/health_information',
        method: 'post',
        data: data
    });
};
// 查看-报告信息
export const reportData = data => {
    return request({
        url: 'info/report_list',
        method: 'post',
        data: data
    });
};
// 生活信息列表
export const getLife = data => {
    return request({
        url: 'salesman/get_life',
        method: 'post',
        data: data
    });
};
// 获取会员等级
export const vipLevel = query => {
    return request({
        url: 'home/vip_level',
        method: 'get',
        params: query
    });
};
// 新增批次报告
export const addReport = data => {
    return request({
        url: 'info/add_report',
        method: 'post',
        data: data
    });
};

// 获取专家列表和时间/转诊
export const getMedic = query => {
    return request({
        url: 'home/medic',
        method: 'get',
        params: query
    });
};
export const getMedicDate = query => {
    return request({
        url: 'home/get_date',
        method: 'get',
        params: query
    });
};
export const getMedicTime = query => {
    return request({
        url: 'home/time',
        method: 'get',
        params: query
    });
};
export const referralFn = data => {
    return request({
        url: 'info/change',
        method: 'post',
        data: data
    });
};

// 获取项目分类
export const getClassify = query => {
    return request({
        url: 'home/get_classify',
        method: 'get',
        params: query
    });
};

// 获取看诊项目/诊疗项目/药品列表
export const getDisease = query => {
    return request({
        url: 'home/get_disease',
        method: 'get',
        params: query
    });
};
export const getDiagnosis = query => {
    return request({
        url: 'home/get_diagnosis',
        method: 'get',
        params: query
    });
};
export const getMedicine = query => {
    return request({
        url: 'home/get_medicine',
        method: 'POST',
        params: query
    });
};
export const getExamination = query => {
  return request({
    url: "home/health_serve",
    method: "get",
    params: query
  });
};

export const vipServe = query => {
    return request({
        url: 'home/vip_serve',
        method: 'get',
        params: query
    });
};

// 增删查改病历模板 
export const addMedical = data => {
    return request({
        url: 'info/add_medical',
        method: 'post',
        data: data
    });
};
export const getMedical = data => {
    return request({
        url: 'info/get_medical',
        method: 'post',
        data: data
    });
};
export const medicalDetails = data => {
    return request({
        url: 'info/medical_details',
        method: 'post',
        data: data
    });
};
export const delMedical = data => {
    return request({
        url: 'info/del_medical',
        method: 'post',
        data: data
    });
};

// 新增诊疗项目模板
export const addDiagnosis = data => {
    return request({
        url: 'info/add_diagnosis',
        method: 'post',
        data: data
    });
};
// 新增药品模板
export const addDrug = data => {
    return request({
        url: 'info/add_drug',
        method: 'post',
        data: data
    });
};
// 查看诊疗项目/药品模板列表
export const diagnosisDrug = data => {
    return request({
        url: 'info/diagnosis_drug',
        method: 'post',
        data: data
    });
};
// 删除诊疗项目/药品模板
export const delTemplate = data => {
    return request({
        url: 'info/del_template',
        method: 'post',
        data: data
    });
};
// 诊疗项目/药品模板详情
export const diagnosisDrugs = data => {
    return request({
        url: 'info/diagnosis_drugs',
        method: 'post',
        data: data
    });
};
// 接诊
export const clinicalReception = data => {
    return request({
        url: 'info/clinical_reception',
        method: 'post',
        data: data
    });
};
// 编辑接诊
export const editOrder = data => {
    return request({
        url: 'info/edit_order',
        method: 'post',
        data: data
    });
};

// 专家工作台
export const specialistAlert = data => {
    return request({
        url: 'info/specialist_alert',
        method: 'post',
        data: data
    });
};
// 护士工作台
export const nurseAlert = data => {
    return request({
        url: 'info/nurse_alert',
        method: 'post',
        data: data
    });
};
// 待确认药品单列表
export const confirmedList = data => {
    return request({
        url: 'info/confirmed_list',
        method: 'post',
        data: data
    });
};
// 待确认药品单-详情
export const getConfirmed = query => {
    return request({
        url: 'home/get_confirmed',
        method: 'get',
        params: query
    });
};
// 新增耗材
export const addConfirmed = data => {
    return request({
        url: 'info/add_confirmed',
        method: 'post',
        data: data
    });
};

// 评分表详情
export const getScore = query => {
    return request({
        url: 'home/get_score',
        method: 'get',
        params: query
    });
};
// 新增评分表
export const addScore = data => {
    return request({
        url: 'info/add_score',
        method: 'post',
        data: data
    });
};

// 获取会员卡等级
export const getCard = query => {
    return request({
        url: 'home/get_card',
        method: 'get',
        params: query
    });
};

// 治疗日志列表
export const logList = data => {
    return request({
        url: 'info/log_list',
        method: 'post',
        data: data
    });
};

// 新增治疗日志
export const addLog = data => {
    return request({
        url: 'info/add_log',
        method: 'post',
        data: data
    });
};

// 删除治疗日志
export const delLog = data => {
    return request({
        url: 'info/del_log',
        method: 'post',
        data: data
    });
};

// 治疗日志详情
export const infoGetLog = data => {
  return request({
    url: "info/get_log",
    method: "post",
    data: data
  });
};

// 删除报告日志
export const infoDelReport = data => {
  return request({
    url: "info/del_report",
    method: "post",
    data: data
  });
};

// 保存治疗日志
export const infoEditLog = data => {
  return request({
    url: "info/edit_log",
    method: "post",
    data: data
  });
};

// 保存报告日志
export const infoEditReport = data => {
  return request({
    url: "info/edit_report",
    method: "post",
    data: data
  });
};

// 保存报告日志
export const infoGetLogs = data => {
  return request({
    url: "info/get_logs",
    method: "post",
    data: data
  });
};

//添加看诊项目
export const addWatchTreament = data =>{
	return request({
		url: 'info/add_disease',
		method: 'post',
		data: data
	})
}

//删除诊疗服务单
export const delDiagnosis = data =>{
	return request({
		url: 'info/del_diagnosis',
		method: 'post',
		data: data
	})
}