<template>
    <div class="about">
        <v-header />
        <v-sidebar />
        <div class="content-box" :class="{ 'content-collapse': collapse }">
            <v-tags></v-tags>
            <div class="content">
                <router-view v-slot="{ Component }">
                    <transition name="move" mode="out-in">
                        <keep-alive :include="tagsList">
                            <component :is="Component"/>
                        </keep-alive>
                    </transition>
                </router-view>
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "../components/Header";
import vSidebar from "../components/Sidebar";
import vTags from "../components/Tags.vue";
export default {
    components: {
        vHeader,
        vSidebar,
        vTags
    },
    computed: {
        tagsList() {
            // let str = this.$store.state.tagsList.map(item => item.name)
            // console.log(str)
            // str = this.delArrVal(str, "newScoring")
            let str = [ 'newEdit', 'memberInfo']
            return str;
        },
        collapse() {
            return this.$store.state.collapse;
        }
    },
    methods:{
        delArrVal(arr,val){    //查找数组中的某个值并全部删除    第一个参数是查找的数组 第二个参数是删除的值
            for(let i=0;i<arr.length;i++){
                if(arr[i]==val){
                    arr.splice(i,1)
                    i--;
                } 
            } 
            return arr;
        } 
    }
};
</script>
