<template>
  <div class="header flex">
    <div style="display:flex">
      <!-- 折叠按钮 -->
      <div class="collapse-btn" @click="collapseChage">
        <i v-if="!collapse" class="el-icon-s-fold"></i>
        <i v-else class="el-icon-s-unfold"></i>
      </div>
      <div class="logo_img">
        <el-image class="logo_img_a" :src="require('../assets/img/logo.png')"></el-image>
      </div>
      <div class="logo">医护端管理系统</div>
    </div>
    <div class="header-right">
      <div class="header-user-con">
        <!-- 消息中心 -->
        <!-- <div class="btn-bell">
          <el-tooltip
            effect="dark"
            :content="message ? `有${message}条未读消息` : `消息中心`"
            placement="bottom"
          >
            <router-link to="/tabs">
              <i class="el-icon-bell"></i>
            </router-link>
          </el-tooltip>
          <span class="btn-bell-badge" v-if="message"></span>
        </div> -->
        <!-- 用户头像 -->
        <div class="user-avator" @click="onUserInfo">
          <el-image style="width: 50px; height: 50px;border-radius: 50%;" :src="userData.image||'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" fit="cover"></el-image>
        </div>
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ userData.name }}
            <i class="el-icon-caret-bottom"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="onUserInfo">个人中心</el-dropdown-item>
              <el-dropdown-item divided command="loginout" >退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { userInfo, outLogin } from "../../src/api/index";
export default {
  data() {
    return {
      fullscreen: false,
      message: 0,
      userData: {
        // "image": require('../assets/img/img.jpg'),
        "image": '',
        "name": '路印',
      }
    };
  },
  created(){
    userInfo().then((res)=>{
      this.userData = res
      // console.log(res)
       localStorage.setItem("userInfo", res); 
      this.$store.commit('setUserInfo',res)
    })
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    },
  },
  methods: {
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        let token = localStorage.getItem('token')
        outLogin({token}).then(()=>{    //退出登录
          localStorage.removeItem("token");
          localStorage.removeItem("types");
          this.$router.push("/login");
        })
      }
    },
    // 侧边栏折叠
    collapseChage() {
      this.$store.commit("hadndleCollapse", !this.collapse);
    },
    onUserInfo() {
      this.$router.push("/UserInfo");
    },
  },
  mounted() {
    if (document.body.clientWidth < 1500) {
      this.collapseChage();
    }
  },
};
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
}
.collapse-btn {
  float: left;
  padding: 0 21px;
  cursor: pointer;
  line-height: 70px;
}
.header .logo {
  margin-left: 40px;
  line-height: 70px;
  color: #333;
}
.header-right {
  padding-right: 50px;
}
.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}
.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}
.btn-bell-badge {
  position: absolute;
  right: 0;
  top: -2px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #fff;
}
.btn-bell .el-icon-bell {
  color: #fff;
}
.user-name {
  margin-left: 10px;
}
.user-avator {
  margin:0 20px;
}
.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.el-dropdown-link {
  color: #333;
  cursor: pointer;
}
.el-dropdown-menu__item {
  text-align: center;
}

.logo_img{
  display: flex;
  align-items: center;
  height: 60px;
  width: 185px;
}
.logo_img_a{
  width: 100%;
}
.el-icon-s-fold:before,.el-icon-s-unfold:before{
  color: #333;
}
</style>
